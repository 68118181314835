@import "./tinker-template/components/containers";
@import "./tinker-template/components/index";
@import "./tinker-template/base/helpers";
@import "./tinker-template/base/vars";

// default
// h2 - 36px
// h4 - 22px

.App {
  height: 100%;
  position: relative;
  color: $dark-color;
  .rs-picker-menu{
    z-index: 1981!important;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .logo {
    width: 130px;
    height: auto;
    padding-top: 20px;
    fill: $dark-color;
  }

  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .schools-page {
    padding-top: 60px;
  }
}

.fade {
  opacity: 1;
}

.modals-nav {
  & > ul {
    display: flex;
    flex-wrap: wrap;
  }
}

#grades-modal-container, 
#absences-modal-container,
#thesis-modal-container,
#mentions-modal-container,
#partial-grades-modal-container {
  .headings {
    display: flex;
    & > div {
      flex: 1;
      display: flex;
      justify-content: center;

      & > p {
        font-weight: bold;
      }
    }
  }
  .list-container {
    .list-item {
      .rs-list-item-content {
        display: flex;
        & > div {
          flex: 1;
          display: flex;
          justify-content: center;
        }
        & > p {
          flex: 1;
          display: flex;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .App {
    .logo {
      width: 130px;
      height: auto;
    }

    h2 {
      font-size: 30px;
    }

    h4 {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 900px) {
  .App {
    .logo {
      width: 100px;
      height: auto;
    }

    h2 {
      font-size: 25px;
    }

    h4 {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 350px) {
  .App {
    .logo {
      width: 80px;
      height: auto;
    }

    h2 {
      font-size: 20px;
    }

    h4 {
      font-size: 14px;
    }
  }
}

// rsuite modal

@media screen and (max-width: 400px) {
  .rs-modal {
    width: unset !important;
  }
}

@media screen and (max-width: 320px) {
  .font-size-20 {
    font-size: 12px !important;
  }
}