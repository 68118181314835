.content-container-col {
  margin-left: auto;
  margin-right: auto;
 // width: 1400px;
  max-width: 90%;
  height: 100%;
}

@media screen and (max-width: 1600px) {
  .content-container-col {
    padding: 0 2rem;
  }
}

.article-container {
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  max-width: 100%;
  height: 100%;
}

@media screen and (max-width: 1100px) {
  .article-container {
    padding: 0 2rem;
  }
}

#form-container {
  margin-left: auto;
  margin-right: auto;
  width: 100% !important;
  max-width: 400px;
  // height: 100%;
}

.wide-screen-helper-container {
  height: 100%;
  width: 100%;
  display: flex;
}

@media screen and (min-width: 2000px) {
  .wide-screen-centered-container {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    max-height: 800px;
    width: 100%;
    max-width: 1200px;
  }
}

.header-container-col {
  margin-left: auto;
  margin-right: auto;
  width: 1800px;
  max-width: 100%;
  height: 100%;
}
