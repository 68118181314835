// Colors

$color-green: #00bfa5 !default;
$color-blue: #03a9f4 !default;
$color-yellow: #f7b643 !default;
$color-red: #f97878 !default;
$color-green-light: #d9f6f2 !default;
$color-blue-light: #daf2fd !default;
$dark-color: #49566d !default;
$color-white: #fff !default;
$border-color: #e7e7e7 !default;
$color-lightgray: #f5f6fa !default;
$color-faded-lightgray: #b6b5b5 !default;
$color-purple: #4e4aef;
$color-blue-2: #05a6f4;
$dashboard-background: #f5f6fa;
$color-succes: #73e600;

// line-height
$paragraph-line-height: 1.7;

//box-shadow
$custom-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

// Background gradients
@mixin bg-gradient-1 {
  background-image: linear-gradient($color-green-light, $color-blue-light);
}

@mixin bg-gradient-2 {
  background-image: linear-gradient($color-green, $color-blue);
}

@mixin bg-gradient-profile {
  background-image: linear-gradient(315deg, #eec0c6 0%, #7ee8fa 74%);
}

// scrollbar
@mixin scrollbar-style {
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $dark-color;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color-faded-lightgray;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: darken($color-faded-lightgray, 5%);
  }
}

// Social colors

$color-instagram: #e4405f;
$color-twitter: #55acee;
$color-pinterest: #bd081c;
$color-facebook: #3b5999;
$color-youtube: #cd201f;
