@import '../base/vars';

//Buttons

.btn-primary {
  background-image: linear-gradient($color-green, $color-blue);
  color: $color-white;
  border: none;
  border-radius: 30px;
  padding-left: 2em;
  padding-right: 2em;

  &:hover {
    background-image: linear-gradient($color-blue, $color-green);
  }
}

.btn-outline-primary {
  background-color: transparent;
  border: none;
  border: 1px solid $color-blue !important;
  border-radius: 30px;
  color: $color-blue;
  padding-left: 2em;
  padding-right: 2em;
  outline: none;

  &:hover {
    background-color: $color-blue;
    color: $color-white;
  }
}

.btn-outline-white {
  background-color: transparent;
  border: none;
  border: 1px solid $color-white !important;
  border-radius: 30px;
  color: $color-white;
  padding-left: 2em;
  padding-right: 2em;
  outline: none;

  &:hover {
    background-color: $color-white;
    color: $color-green;
  }
}

// Special color fonts

.font-color-faded {
  color: $color-faded-lightgray !important;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-40 {
  font-size: 40px;
}

//font-size-14
@media screen and (max-width: 767px) {
  .font-size-14 {
    font-size: 12px;
  }
}

//font-size-40
@media screen and (max-width: 991px) {
  .font-size-40 {
    font-size: 28px;
  }
}

@media screen and (max-width: 760px) {
  .font-size-40 {
    font-size: 20px;
  }
}
