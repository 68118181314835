@import "../../../tinker-template/base/vars";

#header {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $color-white;
  border-bottom: 1px solid $border-color;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  z-index: 201;

  #menu-icon {
    cursor: pointer;
    font-size: 1.2rem;
  }

  #header-logo {
    width: 80px;
    height: auto;
    cursor: pointer;
    fill: $dark-color;
  }

  #header-search-input {
    border: none;
    border: 1px solid $color-blue;
    outline: none;
  }

  .user-icon-container {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-blue;
    border-radius: 50%;
    cursor: pointer;
  }
}

// media queries
@media screen and (max-width: 680px) {
  #header {
    #search-input-col {
      display: none;
    }
  }
}
